import './App.css';
import React from "react";
import './scss/styles2.scss';
import {Download, Mail, Phone} from "react-feather";
import hobbyLogo from "./hobbylist_logo.png";

function App2() {
  return (
      <div>
        <div className={`page-container`}>
          <div className="responsive-container">
            <div className="background">
              <div className="dark-part"/>
              <div className="light-part">
                <div className="bottom-animation">
                  <ul className="squares">
                    <li/>
                    <li/>
                    <li/>
                    <li/>
                    <li/>
                    <li/>
                    <li/>
                    <li/>
                    <li/>
                    <li/>
                  </ul>
                </div>
              </div>
            </div>
            <div className="name-block">
              <div className="name">
                <span> Nate Phan</span>
              </div>
              <div className="main-job">
                <span>Fullstack Web Developer</span>
              </div>
            </div>
            <MainContent/>
          </div>
        </div>
      </div>
  );
}

function MainContent() {
  const [active, setActive] = React.useState("ABOUT");

  return <div className="main-contents">
    <div className="left-part">
      <div className="menu">
        <div className={`entry ${active === "ABOUT"? "active" : ""}`}
             onClick={()=>setActive("ABOUT")}>
          <span>Summary</span>
        </div>
        <div className={`entry ${active === "EXPERIENCE"? "active" : ""}`}
             onClick={()=>setActive("EXPERIENCE")}>
          <span>Experience</span>
        </div>
        <div className={`entry ${active === "PROJECT"? "active" : ""}`}
             onClick={()=>setActive("PROJECT")}>
          <span>Project</span>
        </div>
        <div className={`entry ${active === "RESUME"? "active" : ""}`}
             onClick={()=>setActive("RESUME")}>
          <span>Contact</span>
        </div>
      </div>
    </div>
    <div className="right-part">
      <div className="content">
        <div className={active === "ABOUT"? "show" : ""}>
          <p>
            Self-motivated web developer with five years of experience in
            developing and maintaining multiple web-based projects.
          </p>
          <p>Front end, back end, QA, DB, UI/UX design - I have worn every single one of those hats.
            Good at some, enthusiastic about others.</p>
        </div>
        <div className={active === "EXPERIENCE"? "show" : ""}>
          <div className="job">
            <div className="job-company">
              Benchmark Technologies International
            </div>
            <div className="job-title">
              Developer
            </div>
            <div className="location">
              - Hackensack, NJ • 10/2015 - 10/2020
            </div>
            <ul>
              <li>
                Refactored old IE optimized code bases to modern development standards: split back end from front end, converted JSP pages to HTML with Ajax, cleaned up and redesigned backend structure.
              </li>
              <li>
                Fixed vulnerabilities in SQL, rewrote old queries and improved app performance. (data retrieval time went from minutes to seconds)
              </li>
              <li>
                Proposed new concepts, designed mockups, and developed new UX to enhance user experiences and improve site interactions.
              </li>
              <li>
                Designed and implemented new custom software applications for consulting purposes.
              </li>
            </ul>
          </div>
          <div className="job">
            <div className="job-company">
              Lionbridge
            </div>
            <div className="job-title">
              Senior Rater
            </div>
            <div className="location">- Waltham, MA • 08/2012 - Current
            </div>
            <ul>
              <li>
                Alpha tested, quality controlled, provided feedback and analysis to improve search algorithms for major search engines.
              </li>
            </ul>
          </div>
          <div className="job">
            <div className="job-company">
              Stevens Institute of Technology
            </div>
            <div className="job-title">
              Math Grader
            </div>
            <div className="location">
              - Hoboken, NJ • 09/2009 - 12/2011
            </div>
            <ul>
              <li>
                Homework and exam grader for MA 115, 116 (Calculus 1, 2) and MA 221 (Differential equations).
              </li>
            </ul>
          </div>
        </div>
        <div className={active === "PROJECT"? "show" : ""}>
           <div className="hobby-image">
             <img src={hobbyLogo} alt={"HobbyLists Logo"}/>
           </div>
           <div className="hobby-des">
             <div className="hobbylists-name">
               <a href={"https://hobbylists.net/community/collections"}>HobbyLists</a>
             </div>
             <ul>
               <li>
                 Free growlist management web application written using React for front end and a Java back end, hosted on AWS. Currently has about 15 active users
               </li>
               <li>
                 Users can create personal lists, upload images, and comment on others’ collections. More communication tools to be developed soon.
               </li>
               <li>
                 <a href={"https://hobbylists.net/collection/demo"}>Example</a>
               </li>
             </ul>
           </div>
        </div>
        <div className={active === "RESUME"? "show" : ""}>
          <div className="contact-block">
            <div>
              <div className="icon">
                <Mail/>
              </div>
              <div className="contact-info">
                nate@natephan.com
              </div>
            </div>
            <div>
              <div className="icon">
                <Phone/>
              </div>
              <div className="contact-info">
                (201) 577 - 4096
              </div>
            </div>
            <div>
              <a className="resume-btn"
                 target="_blank"
                 rel="noreferrer"
                 download
                 href={`${process.env.PUBLIC_URL}/resume.pdf`}>
                <div className="icon">
                  <Download/>
                </div>
                <div className="contact-info">
                  Resume
                </div>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default App2;
